<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">Attributes</h6>
      </b-row>

      <router-link tag="div" :to="'/takeaway/products/attributes/new'" class="ml-25 btn btn-primary pull-right">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New</span>
      </router-link>
    </b-card>
    <div>
      <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">
        <!-- Row Loop -->
        <div v-for="(item, index) in items" :id="item.id" :key="index + '-' + item.id" ref="row" class="pb-2">
          <b-card>
            <b-row>
              <b-col md="8">
                <b-row>
                  <!-- Attributes Name -->
                  <b-col md="4">
                    <b-form-group label="Attributes Name" label-for="attributes-name">
                      <b-form-input id="attributes-name" type="text" v-model="item.title" placeholder="Attributes Name" aria-disabled="true" disabled />
                    </b-form-group>
                  </b-col>
                  <!-- Attributes Code -->
                  <b-col md="4">
                    <b-form-group label="Attributes Required" label-for="attributes-required">
                      <b-form-select id="attribute-required" v-model="item.required" :options="optionsRequired" aria-disabled="true" disabled />
                    </b-form-group>
                  </b-col>

                  <!-- Attributes Type -->
                  <b-col md="4">
                    <b-form-group label="Attributes Type" label-for="attributes-type">
                      <b-form-select id="attribute-type" v-model="item.type" :options="optionsType" aria-disabled="true" disabled />
                    </b-form-group>
                  </b-col>

                  <b-col md="4" v-if="item.type == 'multi'">
                    <b-form-group label="Attributes Minimum Amount To Select" label-for="attributes-min_select">
                      <b-form-input
                        id="attributes-min_select"
                        type="text"
                        v-model="item.min_select"
                        aria-disabled="true"
                        disabled
                        placeholder="Attributes Min selection"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="item.type == 'multi'">
                    <b-form-group label="Attributes Maxiumum Amount To Select" label-for="attributes-max_select">
                      <b-form-input
                        id="attributes-max_select"
                        type="text"
                        v-model="item.max_select"
                        aria-disabled="true"
                        disabled
                        placeholder="Attributes Max selection"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Content -->
                  <b-col md="12">
                    <b-form-group :label="'Description'" label-for="category-description">
                      <b-form-textarea
                        id="category-description"
                        type="text"
                        v-model="item.content"
                        aria-disabled="true"
                        disabled
                        placeholder="Attributes Description"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Pirority -->
                  <b-col md="12">
                    <b-form-group :label="'Pirority'" label-for="category-description">
                      <b-form-input id="attributes-priority" type="text" v-model="item.priority" aria-disabled="true" disabled placeholder="Priority" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Options -->
                <template v-if="item.type == 'select' || item.type == 'multi'" :ref="'rowSub'" class="row">
                  <h5>Options List</h5>
                  <b-row v-for="(sub, subIndex) in item.option_values" :key="item.id + '-' + subIndex">
                    <b-col md="6">
                      <b-form-group label="Option Name" :label-for="'option-name' + item.id + '-' + subIndex">
                        <b-form-input
                          :id="'option-name' + item.id + '-' + subIndex"
                          type="text"
                          v-model="sub.value"
                          placeholder="Option Name"
                          aria-disabled="true"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Option Price Change" :label-for="'attributes-price' + item.id + '-' + subIndex">
                        <b-form-input
                          :id="'attributes-price' + item.id + '-' + subIndex"
                          type="number"
                          v-model="sub.price"
                          placeholder="Option Price Change"
                          aria-disabled="true"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
              </b-col>

              <b-col md="4">
                <b-form-group :label="'Assigned Products'" label-for="vat-rate-products_tagged" class="producttag">
                  <v-select id="vat-rate-product" v-model="item.products" multiple :options="productsOptions" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="item.id || item.id_temp == 'posted'">
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <router-link tag="div" :to="'/takeaway/products/attributes/' + item.id" class="ml-25 btn btn-success pull-right">
                  <feather-icon icon="BookIcon" class="mr-25" />
                  <span>Edit</span>
                </router-link>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard, BFormTextarea } from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";

import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    vSelect,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      attributes_raw: [],
      nextTodoId: 1,

      products_raw: [],
      productsOptions: [],

      optionsRequired: [
        {
          value: 0,
          text: "No",
        },
        {
          value: 1,
          text: "Yes",
        },
      ],
      optionsType: [
        {
          value: "multi",
          text: "Multiselect Options",
        },
        {
          value: "select",
          text: "Options",
        },
        {
          value: "text",
          text: "Free text Input",
        },
      ],
    };
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.items = val;
        this.$forceUpdate();
      },
    },
  },
  async beforeCreate() {
    await useJwt
      .list_products()
      .then((response) => {
        localStorage.setItem("userProducts", JSON.stringify(response.data.data));
        this.products_raw = response.data.data;
        console.log(response.data);
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your attributes data from the server.`,
          },
        });
      });

    await useJwt
      .get_attributes(this.id)
      .then((response) => {
        localStorage.setItem("userAttributes", JSON.stringify(response.data.data));
        this.attributes_raw = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your attributes data from the server.`,
          },
        });
      });

    for (let y = 0; y < this.products_raw.length; y++) {
      this.productsOptions.push({
        value: this.products_raw[y].id,
        label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
      });
    }
    let attributes_temp;

    if (this.attributes_raw.length) {
      let item = {};
      for (let x = 0; x < this.attributes_raw.length; x++) {
        // Initialize a new item object for each iteration
        let item = {
          id: this.attributes_raw[x].id,
          title: this.attributes_raw[x].title,
          required: this.attributes_raw[x].required,
          type: this.attributes_raw[x].type,
          content: this.attributes_raw[x].content,
          min_select: this.attributes_raw[x].min_select,
          max_select: this.attributes_raw[x].max_select,
          option_values: JSON.parse(this.attributes_raw[x].option_values),
          priority: this.attributes_raw[x].priority,
          products: [],
        };

        for (let y = 0; y < this.products_raw.length; y++) {
          if (this.products_raw[y].options && this.products_raw[y].options.length > 3) {
            let options_temp = JSON.parse(this.products_raw[y].options);
            if (options_temp && options_temp.length)
              for (let za = 0; za < options_temp.length; za++) {
                if (options_temp[za] && options_temp[za].attributes && options_temp[za].attributes.length)
                  for (let zz = 0; zz < options_temp[za].attributes.length; zz++) {
                    if (this.attributes_raw[x].id == options_temp[za].attributes[zz].id) {
                      item.products.push({
                        value: this.products_raw[y].id,
                        label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title + " " + options_temp[za].name,
                      });
                    }
                  }
              }
          } else if (this.products_raw[y].attributes) {
            let attributes_temp = this.products_raw[y].attributes.split(",");
            attributes_temp = [...new Set(attributes_temp)];
            if (attributes_temp.length) {
              for (let z = 0; z < attributes_temp.length; z++) {
                if (attributes_temp[z] == this.attributes_raw[x].id) {
                  item.products.push({
                    value: this.products_raw[y].id,
                    label: "(#" + this.products_raw[y].id + ") " + this.products_raw[y].title,
                  });
                }
              }
            }
          }
        }

        this.items.push(item);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
